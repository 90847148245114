.view-container {
  width: 100%;
  height: 100%;
}

.tooltip {
  position: fixed;
  visibility: hidden;
  transition: visibility 0.2s;
  //  cubic-bezier(0.23, 1, 0.32, 1),
  //   left 0.2s cubic-bezier(0.23, 1, 0.32, 1),
  //   top 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  color: #fff;
  padding: 6px;
  // 置顶
  z-index: 999;
}

.func-span {
  margin-left: 10px;
}

.ant-radio-group {
  margin-right: 10px;
}